<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <div class="form-group">
        <textarea
          v-model="section[contentAttr]"
          class="form-control"
          rows="5"
          placeholder="Are there any other details your subscribers should know about?"
        ></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
  </div>
</template>

<script>
import NumberInput from './NumberInput'
import ColorInput from './ColorInput'
import AlignInput from './AlignInput'

export default {
  components: {
    NumberInput,
    ColorInput,
    AlignInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },

    device: {
      type: String,
      default: 'desktop',
    },
  },

  computed: {
    contentAttr() {
      let attr = null

      switch (this.device) {
        case 'desktop':
          attr = 'Content'
          break
        case 'mobile':
          attr = 'MobileContent'
          break
        case 'print':
          attr = 'PrintContent'
          break
      }

      return attr
    },
  },
}
</script>
